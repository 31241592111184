import ContestService from "@/services/contest.service";

const contestService = new ContestService();

export default {
  name: 'contest',
  components: {},
  props: [],
  data() {
    return {
      localImage: null,
      file: null,
      contestList: [],
      participants:[],
      Contest: {
        Title: '',
        StartDate: '',
        EndDate: '',
        Rules: ''
      },
      contestCreateModal: false,
      contestParticipantsModal: false,
    }
  },
  computed: {},
  async mounted() {
    await this.getContestList();
  },
  methods: {
    async getContestList() {
      const res = await contestService.list();
      if (res && !res.error) {
        this.contestList = res.data.contests;
      }
    },

    async Create() {
      const formData = new FormData();
      formData.append('Title', this.Contest.Title);
      formData.append('StartDate', this.Contest.StartDate);
      formData.append('EndDate', this.Contest.EndDate);
      formData.append('Rules', this.Contest.Rules);
      formData.append('file', this.file);

      const res = await contestService.create(formData);

      if (res && !res.error) {
        await this.getContestList();
        this.contestCreateModal = false;
      }
    },

    async Remove(contestId) {
      await contestService.remove(contestId);
    },

    async getParticipantsByContest(ContestId){
      const res = await contestService.ParticipantsByContest(ContestId);

      if (res && !res.error) {
        this.participants = res.data.participants;
        this.contestParticipantsModal = true;
      }
    },

    onSelectedImage(event) {
      const file = event.target.files[0];
      if (!file) {
        this.localImage = null;
        this.file = null;
        return;
      }

      this.file = file;

      const fr = new FileReader();
      fr.onload = () => this.localImage = fr.result;
      fr.readAsDataURL(file);
    },

    contestImageURL(contest) {
      return this.$s3_url() + 'contest/' + contest;
    },

    closeCreateModal() {
      const resetForm = {
        Title: '',
        StartDate: '',
        EndDate: '',
      }
      this.localImage = null;
      this.file = null;
      this.Contest = resetForm;
      this.contestCreateModal = false;
    },

    closeParticipantsModal(){
      this.contestParticipantsModal = false;
    }
  }
}


