import _ from 'lodash';

import AgentService from "@/services/agent.service";
import LeaderboardService from "@/services/leaderboard.service";

const service = new AgentService();
const leaderboardService = new LeaderboardService();

export default {
  name: 'leader-board',
  components: {},
  props: {
    company: {type: String, default: null},
    dateRange: {type: String, default: 'ALL_TIME'},
  },
  data() {
    return {
      agents: [],
      isLoading: true,
      totalRows: null,
    }
  },
  computed: {
    top3() {

      if (this.sortedAgents.length <= 3)
        return this.sortedAgents;

      const [top1, top2, top3,] = this.sortedAgents;
      return [top1, top2, top3];
    },
    top3Plus() {
      if (this.sortedAgents.length <= 3)
        return [];
      const [, , , ...arr] = this.sortedAgents;
      return arr;
    },
    sortedAgents() {
      return _
          .chain(this.agents)
          .map(o => {
            o.others = o.leads + o.appointments + o.calls + o.emails;
            o.totals = o.deals + o.others;
            return o;
          })
          .orderBy(['deals', 'others'], ['desc', 'desc'])
          .value();
    },
  },
  async mounted() {
    // await this.getBDCRanking();
    await this.getBdcLeaders();
  },
  methods: {
    async getBDCRanking() {
      const res = await service.getSellersRanking();
      if (res && !res.error) {
        this.agents = res.data;
        this.totalRows = res.data.length;
        this.isLoading = false;
      }
    },

    async getBdcLeaders() {
      const query = {
        year: '2021',
        month: '02'
      }

      await leaderboardService.sellersLeaders(query);
    },

    style(agent, type) {
      let style = {}

      switch (type) {
        case 'deals':
          style = {
            '--deals-bg': '#33AC2E',
            width: `${this._percentage(agent.deals, agent.totals)}%`
          }
          break;
        case 'leads':
          style = {
            '--leads-bg': '#4E77F3',
            width: `${this._percentage(agent.leads, agent.totals)}%`
          }
          break;
        case 'appts':
          style = {
            '--appointments-bg': '#F7C137',
            width: `${this._percentage(agent.appointments, agent.totals)}%`
          }
          break;
        case 'calls':
          style = {
            '--calls-bg': '#00C1D4',
            width: `${this._percentage(agent.calls, agent.totals)}%`
          }
          break;
        case 'emails':
          style = {
            '--emails-bg': '#8C54FF',
            width: `${this._percentage(agent.emails, agent.totals)}%`
          }
          break;
      }

      return style;
    },

    avatar(agent) {
      return `${this.$ws_server()}/api/files/avatar/${agent._id}`;
    },

    _percentage(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue);
    },
  }
}


