import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class ContestService extends IService {
  constructor(http) {
    super(http);
  }

  async create(contest) {
    try {
      let response = await this.http.post(`${server_base}/api/contest`, contest);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list() {
    try {
      let response = await this.http.get(`${server_base}/api/contest`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async ParticipantsByContest(contest) {
    try {
      let response = await this.http.get(`${server_base}/api/contest-participants/participants-by-contest/${contest}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }



  async remove(id) {
    try {
      let response = await this.http.delete(`${server_base}/api/contest`, {id});
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default ContestService;