import IService from './iservice';
import config from "@/config";
import store from "@/store/store";


const server_base = config[config.stage].ws_server;

class LeaderService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async bdcLeaders(query) {
    try {
      const {year, month} = query;
      let response = await this.http.get(`${server_base}/api/leaderboard/bdc?year=${year}&month=${month}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async sellersLeaders(query) {
    try {
      const {year, month} = query;
      let response = await this.http.get(`${server_base}/api/leaderboard/sellers?year=${year}&month=${month}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default LeaderService;